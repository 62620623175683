<template>
  <article>
    <MetaHead
      v-if="nodeData"
      :response-data="nodeData"
    />

    <template v-if="bladesData.length === 0">
      <FullWidthSection
        class="blades__bladeWrapper"
        :class="[`blades__bladeWrapper--dark`]"
      >
        <OverlaySpinner :scoped="true" />
      </FullWidthSection>
      <FullWidthSection
        class="blades__bladeWrapper"
        :class="[`blades__bladeWrapper`]"
      >
        <OverlaySpinner :scoped="true" />
      </FullWidthSection>
    </template>
    <template v-else>
      <div class="blades">
        <template
          v-for="(blade, k) in bladesData"
          :key="k"
        >
          <FullWidthSection
            class="blades__bladeWrapper"
            :class="[`blades__bladeWrapper--${blade.theme}`]"
          >
            <TheBlade
              :id="blade.id"
              :heading="blade.heading"
              :subheading="blade.subheading"
              :body="blade.body"
              :img-src="blade.imgSrc"
              :reverse="blade.reverse"
              :theme="blade.theme"
              :k="k"
            >
              <template
                v-if="blade.cta"
                #cta
              >
                <template v-if="blade.cta.asButton">
                  <BaseButton
                    :to="blade.cta.href"
                    :link="true"
                    :temperature="blade.cta.buttonTemperature"
                  >
                    {{ blade.cta.title }}
                  </BaseButton>
                </template>
                <template v-else>
                  <router-link :to="blade.cta.href">
                    {{ blade.cta.title }}
                  </router-link>
                </template>
              </template>
            </TheBlade>
          </FullWidthSection>

          <template v-if="k === 1 && $slots.default">
            <FullWidthSection class="blades__bladeWrapper">
              <slot />
            </FullWidthSection>
          </template>
        </template>
      </div>
    </template>
  </article>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import * as E from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import { useConfigPageApi } from '~/composables/api/non-commerce/config-page';
import { useDrupalJsonApi } from '~/composables/api/drupal-json-api';
import BaseButton from '~/components/button/BaseButton.vue';
import { notEmpty } from '~/common/empty';
import { NodeLandingPageData } from '~/types/json-api/node';
import { MediaImage } from '~/types/json-api/media';
import { ParagraphBlade } from '~/types/json-api/paragraph';
import { ApiError, Errorable } from '~/composables/api/api-common';
import { useDataCacheStore } from '~/store/data-cache';
import { delay } from '~/common/delay';

const props = defineProps({
  fieldId: {
    type: String,
    required: true,
  },
});

const { t } = useI18n();
const configPageApi = useConfigPageApi();
const drupalJsonApi = useDrupalJsonApi();
const dataCache = useDataCacheStore();

const emit = defineEmits(['loaded']);
const emitLoaded = () => emit('loaded');

interface Blade {
  id?: string,
  heading: string,
  subheading?: string,
  body: string,
  theme: string,
  reverse: boolean,
  imgSrc?: string,
  cta?: {
    title: string,
    href: string,
    asButton: boolean,
    buttonTemperature: string | undefined
  }
}

const nodeData = ref<NodeLandingPageData | null>(null);
const bladesData = ref<Blade[]>([]);

type ReturnData = Errorable<{
  landingPageNode: NodeLandingPageData | null,
  innerBlades: Blade[]
}>

const getBladeData = async (): Promise<ReturnData> => pipe(
  await configPageApi.fetchLandingPage(props.fieldId),
  E.map((response) => {
    const configPage = response.data[0];
    // @ts-ignore
    const nodeId = configPage.relationships[props.fieldId].data.id;
    const landingPageNode = pipe(
      drupalJsonApi.parseIncluded<NodeLandingPageData>(response, nodeId),
      E.match(() => null, (x) => x),
    );

    if (!landingPageNode) {
      return {
        landingPageNode: null,
        innerBlades: [],
      };
    }

    // @ts-ignore
    const bladeIds: string[] = landingPageNode.relationships.field_blades.data.map((x) => x.id);
    const blades: ParagraphBlade[] = bladeIds.map((id) => pipe(
      drupalJsonApi.parseIncluded<ParagraphBlade>(response, id),
      E.match(() => null, (x) => x),
    ))
      .filter(notEmpty);

    return {
      landingPageNode,
      innerBlades: blades.map((blade) => {
        const mediaImg: MediaImage | undefined = blade.relationships.field_media_image.data
          ? pipe(
            drupalJsonApi.parseIncluded<MediaImage>(response, blade.relationships.field_media_image.data.id),
            E.match((x) => undefined, (x) => x),
          )
          : undefined;

        const fieldCta = blade.attributes.field_cta;
        const ctaTheme = blade.attributes.field_cta_theme;
        return {
          id: blade.attributes.field_id,
          heading: blade.attributes.field_heading,
          subheading: blade.attributes.field_subheading ?? '',
          body: blade.attributes.field_body.value,
          imgSrc: mediaImg
            ? drupalJsonApi.parseImageUrlSingle(
              response,
              mediaImg,
              'field_media_image',
              '4_3_sc_800x600',
            )
            : null,
          theme: blade.attributes.field_theme,
          reverse: blade.attributes.field_reverse,
          cta: fieldCta
            ? {
              title: fieldCta.title,
              href: fieldCta.uri.includes('internal:')
                ? fieldCta.uri.replace('internal:', '')
                : fieldCta.uri,
              asButton: ctaTheme !== 'link',
              buttonTemperature: ctaTheme !== 'link'
                ? ctaTheme
                : undefined,
            }
            : undefined,
        };
      }),
    };
  }),
);

const setValues = (rd: ReturnData) => pipe(
  rd,
  E.fold(
    (e: ApiError) => {
    },
    (data) => {
      nodeData.value = data.landingPageNode;
      bladesData.value = data.innerBlades;
    },
  ),
);

dataCache.initPathCache({
  id: [
    'configLandingPage',
    props,
  ],
  getter: getBladeData,
  setter: setValues,
  after: async () => {
    await delay(1);
    emitLoaded();
  },
  includeScopes: false,
  mountDependent: true,
});

</script>

<style scoped lang="scss">
.placeholder {
  height: 27em;

  &:nth-child(odd) {
    background: var(--color--bg-dark);
  }
}

.blades {
  &__bladeWrapper {
    &--dark {
      background: var(--color--bg-dark);
    }

    &:last-child {
      // To offset the footer margin-top.
      margin-bottom: calc(-1 * var(--space-lg));
    }
  }
}
</style>
